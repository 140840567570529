exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gatsby-help-js": () => import("./../../../src/pages/gatsby-help.js" /* webpackChunkName: "component---src-pages-gatsby-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pet-portraits-js": () => import("./../../../src/pages/pet-portraits.js" /* webpackChunkName: "component---src-pages-pet-portraits-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/Pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-studies-js": () => import("./../../../src/pages/studies.js" /* webpackChunkName: "component---src-pages-studies-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-wildlife-js": () => import("./../../../src/pages/wildlife.js" /* webpackChunkName: "component---src-pages-wildlife-js" */)
}

